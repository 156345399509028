import { type FC } from 'react'

import {
    FileDownloadOutlined,
    ArchiveOutlined,
    MoreVert,
    UnarchiveOutlined,
    AccountCircleOutlined,
    Add,
    AssignmentOutlined,
    BarChart,
    BuildCircleOutlined,
    BuildOutlined,
    Business,
    CalculateOutlined,
    Check,
    Clear,
    Close,
    Delete,
    Edit,
    GridView,
    InfoOutlined,
    LibraryAddCheckOutlined,
    LocalShippingOutlined,
    LogoutOutlined,
    NetworkCheck,
    NoteAddOutlined,
    PeopleAltOutlined,
    PersonAdd,
    PersonOutlined,
    ReportOutlined,
    Save,
    SearchOutlined,
    Settings,
    SettingsOutlined,
    Speed,
    StorefrontOutlined,
    ArrowBackOutlined,
    StarOutline,
    Star,
    ExpandOutlined,
    ListOutlined,
    CheckOutlined,
    SellOutlined,
    AddCommentOutlined,
    CommentOutlined,
    CalendarTodayOutlined,
    UploadFileOutlined,
    SendOutlined,
    KeyboardArrowUp,
    KeyboardArrowDown,
    QrCodeScanner,
    ScheduleOutlined,
    MonetizationOnOutlined,
    WorkOutlineOutlined,
    PercentOutlined,
    ListAltOutlined,
    LinkOutlined,
    LinkOffOutlined,
    UploadFile,
    PhotoOutlined,
    ArrowRightOutlined,
    ArrowForward,
    AttachFileOutlined,
    InsertDriveFileOutlined,
    WarningAmberOutlined,
    InsertInvitationOutlined,
    EventBusyOutlined,
    GridViewOutlined,
    RestoreOutlined,
    EventAvailableOutlined,
    DragIndicatorOutlined,
    SwapVertOutlined,
    DoneAll,
    CalendarToday,
    AvTimerOutlined,
    HowToRegOutlined,
    ChevronRight,
    ChevronLeft,
    HelpOutlineOutlined,
    ErrorOutline,
    CheckCircleOutline,
    PlusOneOutlined,
    ReceiptLongOutlined,
    CancelOutlined,
    AttachMoney,
    QrCode2Outlined,
    HomeOutlined,
    ArrowDropDownOutlined,
    DomainOutlined,
    ArrowBack,
    Menu,
    KeyboardArrowDownOutlined,
    KeyboardArrowUpOutlined,
    HistoryToggleOffOutlined,
    TodayOutlined,
    PollOutlined,
    CommuteOutlined,
    ArrowDownward,
    ArrowUpward,
    DragHandle,
    Alarm,
    WidgetsOutlined,
    RefreshOutlined,
    OpenInFullOutlined,
    DragIndicator,
    AccessAlarm,
    WorkspacesOutlined,
    SyncDisabled,
    Sync,
    SensorsOff,
    Sensors,
    ReceiptOutlined,
    Storefront,
    Route,
    KeyboardArrowRight,
    KeyboardArrowLeft,
    ArrowBackIosNew,
    FlashOnOutlined,
    FlashOffOutlined,
    RadioButtonUncheckedOutlined,
    RadioButtonCheckedOutlined,
    CameraswitchOutlined,
    DeleteOutlineOutlined,
    TableRowsOutlined,
    ViewHeadline,
    ViewModule,
    Refresh,
    FilterAltOutlined,
    FileDownload,
    ViewWeekOutlined,
    AccessTimeOutlined,
    CalendarMonthOutlined,
    Rotate90DegreesCcwOutlined,
    ZoomInOutlined,
    FilterNone,
    ContentCopyOutlined,
    PersonAddAltOutlined,
    LockOutlined,
    TrendingDownOutlined,
    TrendingUpOutlined,
    AssignmentReturnedOutlined,
    DescriptionOutlined,
    Download,
    Movie,
    Headset,
    Description,
    InsertDriveFile,
    FileUploadOutlined,
    Image,
    DoneOutlined,
    ArrowUpwardOutlined,
    CheckCircle,
    Cancel,
    AccessTime,
    RemoveOutlined,
    LocationOn,
    PlaceOutlined,
    Code,
    FactoryOutlined,
    Straighten,
    WarehouseOutlined,
    TrendingUp,
} from '@mui/icons-material'

import { ReactComponent as AddPart } from './add-part.svg'
import { ReactComponent as AddLineItem } from './add-to-list-items.svg'
import { ReactComponent as BarcodeScan } from './barcode-scan.svg'
import { ReactComponent as Barcode } from './barcode.svg'
import { ReactComponent as CalendarAlertOutlined } from './calendar-alert-outline.svg'
import { ReactComponent as CalendarClockOutlined } from './calendar-clock-outline.svg'
import { ReactComponent as Checklist } from './checklist.svg'
import { ReactComponent as ClipboardClock } from './clipboard-clock-outline.svg'
import { ReactComponent as CloseCreditPO } from './close-credit-po.svg'
import { ReactComponent as ComponentDescription } from './component-description.svg'
import { ReactComponent as SaveDraft } from './content-save-check-outline.svg'
import { ReactComponent as CostPerVMRSGroup } from './cost-per-vmrs-group.svg'
import { ReactComponent as CreditPO } from './credit-po.svg'
import { ReactComponent as Customers } from './customers.svg'
import { ReactComponent as DvirInProgress } from './dvir-inprogress.svg'
import { ReactComponent as DvirUnresolved } from './dvir-unresolved.svg'
import { ReactComponent as EquipmentCategory } from './equipment-category.svg'
import { ReactComponent as Event } from './event.svg'
import { ReactComponent as Fee } from './fee.svg'
import { ReactComponent as Excel } from './file-excel-box.svg'
import { ReactComponent as Word } from './file-word-box.svg'
import { ReactComponent as FilterActive } from './filter-active.svg'
import { ReactComponent as GoogleMapPin } from './google-map-pin.svg'
import { ReactComponent as Hubometer } from './hubometer.svg'
import { ReactComponent as InventoryAdd } from './inventory-add.svg'
import { ReactComponent as LP } from './lp.svg'
import { ReactComponent as Model } from './model.svg'
import { ReactComponent as MoneyWithArrows } from './money-with-arrows.svg'
import { ReactComponent as Dvir } from './monitor-dvir.svg'
import { ReactComponent as Name } from './name.svg'
import { ReactComponent as PackageClosed } from './package-closed.svg'
import { ReactComponent as Pdf } from './pdf.svg'
import { ReactComponent as WOPurchaseOrder } from './po-work-orders.svg'
import { ReactComponent as ReceiptCrossOutlined } from './receipt-cross-outlined.svg'
import { ReactComponent as DeleteCrossReference } from './remove-cross-reference.svg'
import { ReactComponent as Shop } from './repair-shops.svg'
import { ReactComponent as Shipping } from './shipping.svg'
import { ReactComponent as SortActive } from './sort-active.svg'
import { ReactComponent as Sort } from './sort.svg'
import { ReactComponent as StatusDraft } from './status-draft.svg'
import { ReactComponent as StatusOpen } from './status-open.svg'
import { ReactComponent as Support } from './support.svg'
import { ReactComponent as Tax } from './tax.svg'
import { ReactComponent as Top3Reasons } from './top3-reasons.svg'
import { ReactComponent as TruckRemoved } from './truck-remove-outline.svg'
import { ReactComponent as Undo } from './undo.svg'
import { ReactComponent as UnitExpirations } from './unit-expirations.svg'
import { ReactComponent as VendorParts } from './vendor-parts.svg'
import { ReactComponent as VendorWO } from './vendors-wo.svg'
import { ReactComponent as Vin } from './vin.svg'
import { ReactComponent as Archived } from './widgets-archived.svg'
import { ReactComponent as WoInvoice } from './wo-invoice.svg'

const Icons = {
    RemoveOutlined,
    ReceiptCrossOutlined,
    AssignmentReturnedOutlined,
    Customers,
    PersonAddAltOutlined,
    ContentCopyOutlined,
    FilterNone,
    ZoomInOutlined,
    Rotate90DegreesCcwOutlined,
    CalendarMonthOutlined,
    AccessTimeOutlined,
    ViewWeekOutlined,
    FileDownload,
    FilterAltOutlined,
    Refresh,
    ViewModule,
    ViewHeadline,
    TableRowsOutlined,
    DeleteOutlineOutlined,
    CameraswitchOutlined,
    RadioButtonCheckedOutlined,
    RadioButtonUncheckedOutlined,
    FlashOffOutlined,
    FlashOnOutlined,
    ArrowBackIosNew,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Route,
    Storefront,
    ReceiptOutlined,
    Sensors,
    SensorsOff,
    Sync,
    SyncDisabled,
    WorkspacesOutlined,
    AccessAlarm,
    DragIndicator,
    OpenInFullOutlined,
    RefreshOutlined,
    WidgetsOutlined,
    Alarm,
    DragHandle,
    ArrowUpward,
    ArrowDownward,
    CommuteOutlined,
    PollOutlined,
    TodayOutlined,
    HistoryToggleOffOutlined,
    KeyboardArrowUpOutlined,
    KeyboardArrowDownOutlined,
    Menu,
    ArrowBack,
    DomainOutlined,
    ArrowDropDownOutlined,
    HomeOutlined,
    QrCode2Outlined,
    AttachMoney,
    CancelOutlined,
    ReceiptLongOutlined,
    PlusOneOutlined,
    CheckCircleOutline,
    ErrorOutline,
    HelpOutlineOutlined,
    ChevronLeft,
    ChevronRight,
    HowToRegOutlined,
    AvTimerOutlined,
    CalendarToday,
    DoneAll,
    SwapVertOutlined,
    DragIndicatorOutlined,
    EventAvailableOutlined,
    RestoreOutlined,
    GridViewOutlined,
    EventBusyOutlined,
    InsertInvitationOutlined,
    WarningAmberOutlined,
    InsertDriveFileOutlined,
    AttachFileOutlined,
    ArrowForward,
    ArrowRightOutlined,
    PhotoOutlined,
    UploadFile,
    LinkOffOutlined,
    LinkOutlined,
    ListAltOutlined,
    PercentOutlined,
    WorkOutlineOutlined,
    MonetizationOnOutlined,
    ScheduleOutlined,
    QrCodeScanner,
    KeyboardArrowDown,
    KeyboardArrowUp,
    SendOutlined,
    UploadFileOutlined,
    CalendarTodayOutlined,
    CommentOutlined,
    AddCommentOutlined,
    SellOutlined,
    CheckOutlined,
    ExpandOutlined,
    ArrowBackOutlined,
    LocationOn,
    Star,
    StarOutline,
    ListOutlined,
    Issues: ReportOutlined,
    Fee,
    Dvir,
    VendorWO,
    CalendarAlertOutlined,
    CalendarClockOutlined,
    GoogleMapPin,
    Hubometer,
    DvirInProgress,
    DvirUnresolved,
    InventoryAdd,
    TruckRemoved,
    WOPurchaseOrder,
    CreditPO,
    SaveDraft,
    Shipping,
    CloseCreditPO,
    AddPart,
    AddLineItem,
    DeleteCrossReference,
    BarcodeScan,
    Support,
    MoneyWithArrows,
    Archived,
    ClipboardClock,
    Shop,
    CostPerVMRSGroup,
    Top3Reasons,
    SortActive,
    Sort,
    Dashboard: Speed,
    Units: LocalShippingOutlined,
    PeopleAltOutlined,
    WorkOrders: AssignmentOutlined,
    Reports: BarChart,
    Settings,
    Invitations: PersonAdd,
    Company: Business,
    Profile: AccountCircleOutlined,
    Logout: LogoutOutlined,
    Parts: BuildCircleOutlined,
    Inventory: PackageClosed,
    VendorParts,
    Part: BuildOutlined,
    Delete,
    Export: FileDownloadOutlined,
    Archive: ArchiveOutlined,
    Unarchive: UnarchiveOutlined,
    Options: MoreVert,
    NoteAdd: NoteAddOutlined,
    Edit,
    GridView,
    Add,
    PersonOutlined,
    Save,
    SearchOutlined,
    Clear,
    Close,
    SettingsOutlined,
    Pdf,
    InfoOutlined,
    SelectAll: LibraryAddCheckOutlined,
    Check,
    Calculate: CalculateOutlined,
    Odometer: NetworkCheck,
    Vendors: StorefrontOutlined,
    FilterActive,
    LP,
    LockOutlined,
    TrendingDownOutlined,
    TrendingUpOutlined,
    DescriptionOutlined,
    Download,
    Movie,
    Headset,
    Description,
    InsertDriveFile,
    Event,
    Excel,
    Word,
    FileUploadOutlined,
    UnitExpirations,
    Image,
    WoInvoice,
    DoneOutlined,
    Tax,
    ArrowUpwardOutlined,
    CheckCircle,
    Cancel,
    StatusOpen,
    StatusDraft,
    AccessTime,
    Undo,
    Name,
    Vin,
    EquipmentCategory,
    Model,
    Checklist,
    PlaceOutlined,
    Code,
    ComponentDescription,
    FactoryOutlined,
    Barcode,
    Straighten,
    WarehouseOutlined,
    TrendingUp,
} satisfies Record<string, FC>

export default Icons
