import { useShowContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import images from 'assets/images'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    List,
    ListAvatar,
    ListBase,
    type ListSortContentProps,
} from 'components'
import { urls } from 'configs'
import { type ActionChildren, deleteOneAction, type ResourceType, type SortPayload } from 'core'
import {
    type AssignmentModel,
    assignmentsResource,
    assignmentFields,
    deleteAssignmentParams,
    AssignmentsDrawer,
} from 'resources/assignments'
import { createdField } from 'resources/base'
import { customerFields } from 'resources/customers'
import { Anchor } from 'ui'

import Header from './Header'

const defaultSort: SortPayload<AssignmentModel> = {
    field: 'start',
    order: 'DESC',
}

const UnitAssignmentsList = () => {
    const { record, isLoading } = useShowContext()

    return (
        <ListBase
            isLoading={isLoading}
            sort={defaultSort}
            resource={assignmentsResource.resource}
            filter={{
                unitId: record?.id,
            }}
            preferencesResource={preferencesResource}
        >
            <Header />
            <List
                isLoading={isLoading}
                preferencesResource={preferencesResource}
                cardsCfg={cardsCfg}
                columnsCfg={columnsCfg}
                filtersCfg={filtersCfg}
                sortCfg={sortCfg}
                disableExportButton
                listFTUProps={{
                    title: 'No Assignments',
                    linkText: (
                        <AssignmentsDrawer defaultValues={{ unit: record?.id }}>
                            {(open) => <Anchor onClick={open}>Create Assignment</Anchor>}
                        </AssignmentsDrawer>
                    ),
                    imageSrc: images.expirations,
                    linkAction: () => {
                        //
                    },
                    action: null,
                }}
            />
        </ListBase>
    )
}

export default UnitAssignmentsList

const preferencesResource: ResourceType = {
    name: 'customer-assignments',
    resource: 'customer-assignments',
}
const navigateTo = (id: Identifier) => {
    return `${urls.customers}/${id}`
}

const actions = (record: AssignmentModel, children: ActionChildren) => [
    <AssignmentsDrawer
        id={record.id}
        key="edit"
    >
        {(open) =>
            children({
                title: 'Edit',
                Icon: Icons.Edit,
                onClick: open,
            })
        }
    </AssignmentsDrawer>,
    deleteOneAction({
        children,
        id: record.id,
        ...deleteAssignmentParams,
    }),
]

const cardsCfg: CardListConfig<AssignmentModel> = {
    titleSource: (record) => customerFields.self.value(record.customerData),
    titleLink: (record) => navigateTo(record.customerData.id),
    subTitleSource: (record) => assignmentFields.status.value(record.status),
    imageSource: (record) => record.customerData.logo,
    defaultImage: <customerFields.avatar.Icon />,
    details: [
        assignmentFields.startDate.dataCardRow(),
        assignmentFields.endDate.dataCardRow(),
        assignmentFields.accumulatedCost.dataCardRow(),
    ],
    actions: (record, { children }) => actions(record, children),
}

const columnsCfg: DatagridColumnsProps<AssignmentModel & { photo: string }> = {
    resetColumns: {
        [createdField.source]: false,
    },
    constantColumns: {
        unit: true,
    },
    checkboxSelection: false,
    columns: [
        {
            field: 'photo',
            headerName: 'Avatar',
            maxWidth: 72,
            renderCell: ({ row }) => (
                <ListAvatar
                    id={row.id}
                    imageSrc={row.customerData.logo}
                    defaultImage={<customerFields.avatar.Icon />}
                    customPath={navigateTo(row.customerData.id)}
                />
            ),
        },
        customerFields.self.tableColumn({
            dataToRecord: (record) => record.customerData,
            withLink: true,
        }),
        assignmentFields.startDate.tableColumn(),
        assignmentFields.endDate.tableColumn(),
        assignmentFields.status.tableColumn(),
        createdField.tableColumn({ dataToValue: (record: AssignmentModel) => record.created }),
        assignmentFields.accumulatedCost.tableColumn(),
    ],
    actions: ({ row }, { children }) => actions(row, children),
}

const filtersCfg: FilterConfig<AssignmentModel> = {
    filters: [
        customerFields.self.filter(),
        assignmentFields.startDate.filter(),
        assignmentFields.endDate.filter(),
        assignmentFields.status.filter(),
        assignmentFields.accumulatedCost.filter(),
        createdField.filter(),
    ],
}

const sortCfg: ListSortContentProps<AssignmentModel> = {
    sortBy: [
        customerFields.self.sort(),
        assignmentFields.startDate.sort(),
        assignmentFields.endDate.sort(),
        assignmentFields.accumulatedCost.sort(),
        createdField.sort(),
    ],
}
