import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { Order } from 'appTypes'
import images from 'assets/images'
import {
    AddButton,
    type CardListConfig,
    type DatagridColumnsProps,
    List,
    ListBase,
    type ListBulkActions,
    type ListSortContentProps,
    phoneMaskResolver,
} from 'components'
import { deleteManyFromListAction, multiselectAction, type SortPayload } from 'core'
import { createdField } from 'resources/base'
import { MemberIcon } from 'resources/members'
import { Anchor, DataAvatar, PageContent } from 'ui'

import { type ContactModel } from '../types'
import { contactActions } from '../utils'

import { ContactDrawerToggler } from './ContactDrawerToggler'
import { ContactResource } from './ContactResource'
import { ContactsHeader } from './ContactsHeader'

interface Props {
    exportName: (record: any) => string
    resourceName: string
}

const ContactsListPage: FC<Props> = ({ exportName: exportNameProp, resourceName }) => {
    const record = useRecordContext()

    const exportName = (record ? exportNameProp(record) : '') + '-contact-persons'

    return (
        <PageContent>
            <ContactResource resourceName={resourceName}>
                <ListBase
                    sort={defaultSort}
                    isLoading={!record}
                >
                    <ContactsHeader
                        actions={
                            <ContactDrawerToggler>
                                {({ onClick }) => (
                                    <AddButton
                                        title="Add Contact Person"
                                        onClick={onClick}
                                    />
                                )}
                            </ContactDrawerToggler>
                        }
                    />
                    <List
                        exportFileName={exportName}
                        disableManageColumns
                        isLoading={!record}
                        sortCfg={sortConfig}
                        columnsCfg={columnsConfig}
                        cardsCfg={cardsConfig}
                        bulkActions={bulkActions}
                        listFTUProps={{
                            imageSrc: images.noVendorContacts,
                            secondaryTitle: null,
                            linkText: (
                                <ContactDrawerToggler>
                                    {({ onClick }) => (
                                        <Anchor onClick={onClick}>Add Contact Person</Anchor>
                                    )}
                                </ContactDrawerToggler>
                            ),
                            linkAction: (e) => {
                                e.preventDefault()
                            },
                        }}
                    />
                </ListBase>
            </ContactResource>
        </PageContent>
    )
}

export default ContactsListPage

const defaultSort: SortPayload<ContactModel> = {
    field: 'created',
    order: Order.DESC,
}

const sortConfig: ListSortContentProps<ContactModel> = {
    sortBy: [
        createdField.sort(),
        {
            id: 'name',
            label: 'Name',
        },
        {
            id: 'email',
            label: 'Email Address',
        },
        {
            id: 'phone',
            label: 'Phone Number',
        },
    ],
}

const columnsConfig: DatagridColumnsProps<ContactModel> = {
    columns: [
        {
            field: 'avatar' as keyof ContactModel,
            headerName: 'Avatar',
            maxWidth: 72,
            renderCell: (cell) => <DataAvatar defaultImage={<MemberIcon record={cell.row} />} />,
        },
        {
            field: 'name',
            headerName: 'Name',
            renderCell: ({ row }) =>
                row.name ? (
                    <ContactDrawerToggler id={row.id}>
                        {({ onClick }) => <Anchor onClick={onClick}>{row.name}</Anchor>}
                    </ContactDrawerToggler>
                ) : null,
        },
        {
            field: 'email',
            headerName: 'Email Address',
        },
        {
            field: 'phone',
            headerName: 'Phone Number',
            renderCell: ({ row }) => phoneMaskResolver(row.phone),
        },
    ],
    actions: ({ row }, params) => contactActions(row, params),
}

const cardsConfig: CardListConfig<ContactModel> = {
    titleSource: (record) => record.name || '-',
    disableTitleLink: true,
    defaultImage: (record) => <MemberIcon record={record} />,
    details: [
        {
            source: 'email',
            label: 'Email Address',
        },
        {
            source: 'phone',
            label: 'Phone Number',
            render: (value) => phoneMaskResolver(value),
        },
    ],
    actions: (record, params) => {
        const children = params.children
        return [
            multiselectAction({
                children,
                id: record.id,
            }),
            ...contactActions(record, params),
        ]
    },
}

const bulkActions: ListBulkActions = ({ children }) => {
    return [
        deleteManyFromListAction({
            children,
            confirmConfig: {
                title: 'Are you sure want to delete the selected Contact Persons?',
            },
        }),
    ]
}
