import { createModifications, ModificationProvider } from 'context'
import { type AssignmentsDrawerModifications } from 'resources/assignments'
import { PageContent } from 'ui'

import { UnitAssignmentsList } from './components'

const UnitAssignments = () => {
    return (
        <PageContent>
            <ModificationProvider value={modifications}>
                <UnitAssignmentsList />
            </ModificationProvider>
        </PageContent>
    )
}

export default UnitAssignments

const modifications = createModifications<AssignmentsDrawerModifications>({
    assignmentForm: {
        disabledFields: { unit: true },
    },
})
