import { useShowContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import images from 'assets/images'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    List,
    ListAvatar,
    ListBase,
    type ListSortContentProps,
} from 'components'
import { urls } from 'configs'
import { type ActionChildren, deleteOneAction, type ResourceType, type SortPayload } from 'core'
import {
    type AssignmentModel,
    assignmentsResource,
    assignmentFields,
    deleteAssignmentParams,
    AssignmentsDrawer,
} from 'resources/assignments'
import { createdField } from 'resources/base'
import { type CustomerModel } from 'resources/customers'
import { unitFields } from 'resources/units'
import { CK2Field } from 'resources/vmrs'
import { Anchor } from 'ui'

import Header from './Header'

const defaultSort: SortPayload<AssignmentModel> = {
    field: 'start',
    order: 'DESC',
}
const CustomerAssignmentsList = () => {
    const { record, isLoading } = useShowContext<CustomerModel>()
    return (
        <ListBase
            isLoading={isLoading}
            sort={defaultSort}
            resource={assignmentsResource.resource}
            filter={{
                customerId: record?.id,
            }}
            preferencesResource={preferencesResource}
        >
            <Header />
            <List
                isLoading={isLoading}
                preferencesResource={preferencesResource}
                cardsCfg={cardsCfg}
                columnsCfg={columnsCfg}
                filtersCfg={filtersCfg}
                sortCfg={sortCfg}
                disableExportButton
                listFTUProps={{
                    title: 'No Assignments',
                    linkText: (
                        <AssignmentsDrawer defaultValues={{ customer: record?.id }}>
                            {(open) => <Anchor onClick={open}>Create Assignment</Anchor>}
                        </AssignmentsDrawer>
                    ),
                    imageSrc: images.expirations,
                    linkAction: () => {
                        //
                    },
                    action: null,
                }}
            />
        </ListBase>
    )
}

export default CustomerAssignmentsList

const preferencesResource: ResourceType = {
    name: 'unit-assignments',
    resource: 'unit-assignments',
}

const actions = (record: AssignmentModel, children: ActionChildren) => [
    <AssignmentsDrawer
        key="edit"
        id={record.id}
    >
        {(open) =>
            children({
                title: 'Edit',
                Icon: Icons.Edit,
                onClick: open,
            })
        }
    </AssignmentsDrawer>,
    deleteOneAction({
        children,
        id: record.id,
        ...deleteAssignmentParams,
    }),
]

const navigateTo = (id: Identifier) => {
    return `${urls.units}/${id}`
}

const cardsCfg: CardListConfig<AssignmentModel & { vmrsEquipmentCategory: string }> = {
    titleSource: (record) => unitFields.self.value(record.unitData),
    titleLink: (record) => navigateTo(record.unitData.id),
    subTitleSource: (record) => assignmentFields.status.value(record.status),
    imageSource: (record) => record.unitData.photo,
    defaultImage: <unitFields.avatar.Icon />,
    details: [
        CK2Field.cardRow({
            id: 'vmrsEquipmentCategory',
            dataToRecord: (record: AssignmentModel) => record.unitData.vmrsEquipmentCategoryData,
        }),
        assignmentFields.startDate.dataCardRow(),
        assignmentFields.endDate.dataCardRow(),
        assignmentFields.accumulatedCost.dataCardRow(),
    ],
    actions: (record, { children }) => actions(record, children),
}

const columnsCfg: DatagridColumnsProps<
    AssignmentModel & { photo: string; vmrsEquipmentCategory: string }
> = {
    resetColumns: {
        [createdField.source]: false,
    },
    constantColumns: {
        unit: true,
    },
    checkboxSelection: false,
    columns: [
        {
            field: 'photo',
            headerName: 'Avatar',
            maxWidth: 72,
            renderCell: ({ row }) => (
                <ListAvatar
                    linkProps={{
                        'aria-label': `View unit with unit number ${row.unitData.number}`,
                    }}
                    id={row.unitData.id}
                    imageSrc={row.unitData.photo}
                    defaultImage={<unitFields.avatar.Icon />}
                    customPath={navigateTo(row.unitData.id)}
                />
            ),
        },
        unitFields.self.tableColumn({ dataToRecord: (record) => record.unitData, withLink: true }),
        CK2Field.column({
            id: 'vmrsEquipmentCategory',
            dataToRecord: (record: AssignmentModel) => record.unitData.vmrsEquipmentCategoryData,
        }),
        assignmentFields.startDate.tableColumn(),
        assignmentFields.endDate.tableColumn(),
        assignmentFields.status.tableColumn(),
        createdField.tableColumn({ dataToValue: (record: AssignmentModel) => record.created }),
        assignmentFields.accumulatedCost.tableColumn(),
    ],
    actions: ({ row }, { children }) => actions(row, children),
}

const filtersCfg: FilterConfig<AssignmentModel & { vmrsEquipmentCategory: string }> = {
    filters: [
        unitFields.self.filter(),
        CK2Field.filter({ id: 'vmrsEquipmentCategory' }),
        assignmentFields.startDate.filter(),
        assignmentFields.endDate.filter(),
        assignmentFields.status.filter(),
        assignmentFields.accumulatedCost.filter(),
        createdField.filter(),
    ],
}

const sortCfg: ListSortContentProps<AssignmentModel & { vmrsEquipmentCategory: string }> = {
    sortBy: [
        unitFields.self.sort(),
        CK2Field.sort({ id: 'vmrsEquipmentCategory' }),
        assignmentFields.startDate.sort(),
        assignmentFields.endDate.sort(),
        assignmentFields.accumulatedCost.sort(),
        createdField.sort(),
    ],
}
