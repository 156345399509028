import { type FC } from 'react'

import { TextInput, TelInput, EmailInput } from 'components'
import { maxLengthValidation } from 'core'
import { GridItem, GridContainer, Alert, Typography } from 'ui'

export interface ContactFormProps {
    showAlert?: boolean
}

export const ContactForm: FC<ContactFormProps> = ({ showAlert }) => {
    return (
        <>
            {showAlert ? <ContactFormAlert /> : null}
            <GridContainer>
                <GridItem>
                    <TextInput
                        source="name"
                        label="Name"
                        validate={maxLengthValidation}
                    />
                </GridItem>
                <GridItem>
                    <EmailInput />
                </GridItem>
                <GridItem>
                    <TelInput />
                </GridItem>
            </GridContainer>
        </>
    )
}

const ContactFormAlert: FC = () => {
    return (
        <Alert
            severity="info"
            sx={{ mb: '20px', alignItems: 'center' }}
        >
            <Typography
                color="inherit"
                variant="body2"
            >
                Purchase Orders can be sent only to contacts with an email address.
            </Typography>
        </Alert>
    )
}
