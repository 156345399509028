import {
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    List,
    ListBase,
    type ListSortContentProps,
} from 'components'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { type AssignmentModel, assignmentsResource, assignmentFields } from 'resources/assignments'
import { createdField } from 'resources/base'
import { customerFields } from 'resources/customers'
import { unitFields, type UnitModel } from 'resources/units'
import { CK2Field } from 'resources/vmrs'
import { PageContent } from 'ui'

import { ReportsHeader } from '../components'

const defaultSort: SortPayload<AssignmentModel> = {
    field: 'start',
    order: 'DESC',
}

const Assignments = () => {
    return (
        <ResourceContextProviderWithClearEffect value={assignmentsResource}>
            <ListBase sort={defaultSort}>
                <ReportsHeader>Assignments</ReportsHeader>
                <PageContent>
                    <List
                        filtersCfg={filtersCfg}
                        sortCfg={sortCfg}
                        columnsCfg={columnsCfg}
                        cardsCfg={cardsCfg}
                    />
                </PageContent>
            </ListBase>
        </ResourceContextProviderWithClearEffect>
    )
}

export default Assignments

const cardsCfg: CardListConfig<AssignmentModel & { vmrsEquipmentCategory: string }> = {
    titleSource: (record) => unitFields.self.linkValue(record.unitData),
    disableTitleLink: true,
    defaultImage: null,
    details: [
        CK2Field.cardRow({
            id: 'vmrsEquipmentCategory',
            dataToRecord: (record: UnitModel) => record.vmrsEquipmentCategoryData,
        }),
        customerFields.self.dataCardRow({
            dataToRecord: (record) => record.customerData,
            label: 'Customer Name',
            withLink: true,
        }),
        assignmentFields.startDate.dataCardRow(),
        assignmentFields.endDate.dataCardRow(),
        assignmentFields.status.dataCardRow(),
        assignmentFields.accumulatedCost.dataCardRow(),
    ],
    actions: null,
}

const columnsCfg: DatagridColumnsProps<AssignmentModel & { vmrsEquipmentCategory: string }> = {
    resetColumns: {
        [createdField.source]: false,
    },
    constantColumns: {
        unit: true,
    },
    checkboxSelection: false,
    columns: [
        unitFields.self.tableColumn({ dataToRecord: (record) => record.unitData, withLink: true }),
        CK2Field.column({
            id: 'vmrsEquipmentCategory',
            dataToRecord: (record: AssignmentModel) => record.unitData.vmrsEquipmentCategoryData,
        }),
        customerFields.self.tableColumn({
            dataToRecord: (record) => record.customerData,
            label: 'Customer Name',
            withLink: true,
        }),
        assignmentFields.startDate.tableColumn(),
        assignmentFields.endDate.tableColumn(),
        assignmentFields.status.tableColumn(),
        createdField.tableColumn({ dataToValue: (record: AssignmentModel) => record.created }),
        assignmentFields.accumulatedCost.tableColumn(),
    ],
    actions: null,
}

const filtersCfg: FilterConfig<AssignmentModel & { vmrsEquipmentCategory: string }> = {
    filters: [
        unitFields.self.filter(),
        CK2Field.filter({ id: 'vmrsEquipmentCategory' }),
        customerFields.self.filter({
            label: 'Customer Name',
        }),
        assignmentFields.startDate.filter(),
        assignmentFields.endDate.filter(),
        assignmentFields.status.filter(),
        assignmentFields.accumulatedCost.filter(),
        createdField.filter(),
    ],
}

const sortCfg: ListSortContentProps<AssignmentModel & { vmrsEquipmentCategory: string }> = {
    sortBy: [
        unitFields.self.sort(),
        CK2Field.sort({ id: 'vmrsEquipmentCategory' }),
        customerFields.self.sort({
            label: 'Customer Name',
        }),
        assignmentFields.startDate.sort(),
        assignmentFields.endDate.sort(),
        assignmentFields.accumulatedCost.sort(),
        createdField.sort(),
    ],
}
