import { type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { useModificationContext } from 'context'

import { assignmentsResource, assignmentsSerializer } from '../utils'

import AssignmentsForm, { type AssignmentsFormProps } from './AssignmentsForm'

export interface AssignmentsDrawerProps {
    children: (open: () => void) => ReactElement
    id?: Identifier
    defaultValues?: any
}
export interface AssignmentsDrawerModifications {
    assignmentForm: AssignmentsFormProps
}
const AssignmentsDrawer = ({ children, id, defaultValues }: AssignmentsDrawerProps) => {
    const open = useOpenUtilityDrawer()
    const modification = useModificationContext<AssignmentsDrawerModifications>()

    return children(() => {
        open({
            extraArgs: {
                id,
                resource: assignmentsResource,
            },
            drawerArgs: {
                title: id ? 'Edit Assignment' : 'Create Assignment',
                renderWrapper: (props) => (
                    <UtilityDrawerEditor
                        serializer={assignmentsSerializer}
                        defaultValues={defaultValues}
                        {...props}
                    />
                ),
                renderContent: () => <AssignmentsForm {...modification('assignmentForm')} />,
            },
        })
    })
}

export default AssignmentsDrawer
