import { type FC } from 'react'

import Icons from 'assets/icons'
import { DataAvatar } from 'ui'

import { type UnitModel } from '../types'

interface Props {
    record: Pick<UnitModel, 'photo' | 'archived'>
}

const Avatar: FC<Props> = ({ record }) => (
    <DataAvatar
        imageSrc={record.photo}
        defaultImage={<Icons.Units />}
    />
)

export const avatar = {
    source: 'photo' satisfies keyof UnitModel,
    label: 'Avatar',
    Icon: Icons.Units,
    Value: Avatar,
}
