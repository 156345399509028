import { type Serializer, type ResourceType, type DeleteOneParams } from 'core'

import { type AssignmentModel } from './types'

export const assignmentsResource: ResourceType = {
    resource: 'assignments',
    name: 'assignments',
}

export const assignmentsSerializer: Serializer<AssignmentModel> = [
    { name: 'start', parse: 'dateTime' },
    { name: 'end', parse: 'dateTime' },
    'unit',
    'customer',
]

export const deleteAssignmentParams: DeleteOneParams = {
    confirmConfig: {
        title: 'Are you sure you want to delete this assignment?',
    },
}
