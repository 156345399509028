import { type FC } from 'react'

import Icons from 'assets/icons'
import { SelectorOption, DialogSelector } from 'components'
import { AddressSelectOption } from 'resources/address'
import { TagsInSelector } from 'resources/tags'
import { Button } from 'ui'

import vendorFields from '../fields'
import { type VendorType } from '../types'
import { formatVendorTypes, vendorsResource } from '../utils'

import { VendorDrawerToggler } from './VendorDrawerToggler'

import type VendorModel from '../types'

interface VendorInputProps {
    disabled?: boolean
    vendorType?: VendorType
    onSelectedChange?: (record: VendorModel) => void
    required?: boolean
    contextId?: string
    source?: string
    label?: string
}

export const VendorInput: FC<VendorInputProps> = ({
    source = 'vendor',
    label = 'Vendor',
    disabled,
    vendorType,
    onSelectedChange,
    required,
    contextId,
}) => {
    return (
        <>
            <DialogSelector<VendorModel>
                reference={vendorsResource.resource}
                source={source}
                defaultSelectorProps={{
                    label,
                }}
                referenceFilter={vendorType ? { type: vendorType } : {}}
                disabled={disabled}
                required={required}
                onSelectedChange={({ selected }) => {
                    onSelectedChange?.(selected)
                }}
                queryOptions={() => ({ meta: { query: { contextId } } })}
                defaultSelectorValueSource={({ selected }) => selected?.name}
                titleSource={() => 'Vendors'}
                renderItem={renderOption}
                perPage={100}
                renderNextToResultCount={({ control, onSelect }) => (
                    <VendorDrawerToggler
                        onSuccess={(record) => {
                            onSelect(record.id)
                            control.refetch()
                        }}
                        shortSuccessMessage
                        vendorFormProps={
                            vendorType
                                ? {
                                      typeInputProps: {
                                          defaultValue: [vendorType],
                                          choices: (choices) =>
                                              choices.map((choice) =>
                                                  choice.id === vendorType
                                                      ? {
                                                            ...choice,
                                                            disabled: true,
                                                        }
                                                      : choice,
                                              ),
                                      },
                                  }
                                : {}
                        }
                    >
                        {(open) =>
                            control.isFetching ? null : (
                                <Button
                                    startIcon={<Icons.Add />}
                                    variant="contained"
                                    onClick={open}
                                >
                                    Create Vendor
                                </Button>
                            )
                        }
                    </VendorDrawerToggler>
                )}
                noResults={({ searchValue }) => {
                    if (!searchValue) {
                        return {
                            title: 'No Vendors Added',
                            text: '',
                            image: (images) => images.listEmpty,
                            imageWidth: '180px',
                        }
                    }
                }}
            />
        </>
    )
}

const renderOption = (record: VendorModel) => (
    <SelectorOption>
        <SelectorOption.Title avatar={<vendorFields.avatar.Value record={record} />}>
            {record.name}
        </SelectorOption.Title>
        <SelectorOption.Details>
            <SelectorOption.Row
                label="Vendor Type"
                Icon={Icons.Checklist}
            >
                {formatVendorTypes(record.type)}
            </SelectorOption.Row>

            <AddressSelectOption record={record} />
        </SelectorOption.Details>
        <TagsInSelector tags={record.tagsData} />
    </SelectorOption>
)
